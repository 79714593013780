<template>
    <div>
      <section class="selfcare-title d-flex">
        <div class="px-2">
          <a @click="goBack()" class="custom-link text-light">
            <i class="bi bi-arrow-left"></i>
          </a>
        </div>
        <div class="text-light width-available text-center">
          <strong> Frequently Asked Questions </strong>
        </div>
      </section>
      <section class="selfcare-title d-flex">
        <div class="px-1 textColor" v-html="content"></div>
      </section>
    </div>
  </template>
  
  <script>
  import axios from "@/services/cms";
  
  export default {
    name: "BonusTnC",
    components: {},
    data() {
      return {
        content: "",
      };
    },
    mounted: function () {
      this.$store.dispatch("setCurrentPage", "BonusTnC");
      this.reloadProfile();
      this.getFaq();
    },
    methods: {
      async getFaq() {
        try {
          const response = await axios.get("/get/bonustnc", {
            headers: {
              Accept: "application/json",
            },
          });
          if (response.status === 200) {
            this.content = response.data.content; 
            console.log(response.data);
          }
        } catch (error) {
          console.error(
            "An error occurred while fetching Bonus terms and conditions",
            error.response?.data || error.message
          );
        }
      },
    },
  };
  </script>
  <style>
.textColor{
  color: black;
}
</style>